import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import 'swagger-ui-react/swagger-ui.css'
import { Home } from './Home.js'
import { Reports } from './Reports.js'
import { ReportDetail } from './ReportDetail.js'

function App() {
  return (
    <Router>
      <Container fluid="xs">
      <Navbar sticky="top" expand="sm" bg="dark" variant="dark">
        <Navbar.Brand as={Link} className="offset-md-3" to="/">
          <img
              src="/PABSEO.png"
              width="120"
              className="d-inline-block align-top"
              alt="SEO GUATEYA - PABSEO logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav>
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/all-reports">
            Reports
          </Nav.Link>
        </Nav>
      </Navbar>
      </Container>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
       
        <Route path="/r/:id">
          <ReportDetail />
        </Route>
        <Route exact path="/all-reports">
          <Reports />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  )
}

function NoMatch() {
  const location = useLocation()
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}

export default App
