import React, { useState } from 'react'
import { Spinner, Container, Row, Col } from 'react-bootstrap'
import { GenerateReportForm } from './GenerateReportForm'
import { AlertError } from './AlertError'
import { isBrowser, isMobile } from 'react-device-detect'
import ReportViewer from 'react-lighthouse-viewer'

export const Home = () => {
  const [jsonReport, setJsonReport] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  function setReportData(report) {
    setJsonReport(JSON.parse(report.raw_json))
  }

  return (
    <div>
      <header className="bg-primary text-white">
        <Container>
          <Row>
            <Col xs={12} md={7}>
              <div className="text-center">
                <h1>How fast is your website?</h1>
                <p className="lead">Try out PABSEO by generating website performance report below:</p>
                <div className="justify-content-center">
                  <GenerateReportForm onSuccess={setReportData} onLoading={setLoading} onError={setError} />
                </div>
              </div>
            </Col>
            {isBrowser && <HomeContent />}
          </Row>
        </Container>
      </header>
      <div className="container-fluid text-center">
        {loading === true && (
          <div className="mt-3 text-center">
            <p>The analysis has started. This takes between 10 and 20 seconds to complete. If it shows a "snap error" your website is not optimized, at PABSEO we do not support large pages for analysis. If you like, you can consult our complementary tool for analysis of home pages only.</p>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {loading === false && jsonReport !== '' && <ReportViewer json={jsonReport} />}
        {Object.keys(error).length > 0 && (
            <AlertError error={error} />
        )}
      </div>
      {isMobile && (
        <header className="bg-primary text-white">
          <Container>
            <Row>
              <HomeContent />
            </Row>
          </Container>
        </header>
      )}
    </div>
  )
}

function HomeContent() {
  return (
    <Col xs={12} md={5}>
      <h2>Performance Optimization</h2>
      <p className="lead">
      PABSEO Guateya helps you optimize your web applications for speed
      by generating detailed speed reports.
      </p>
      <p className="lead">
      Do you want to improve and/or fix your problems on your website? you can hire
      Consider starring the {' '}
        <a className="text-light font-weight-bold" href="https://www.guateya.com/servicios/seo/">
         Guateya SEO Service
        </a>, it will be a pleasure to be part of the launch of your website.
      </p>
    </Col>
  )
}
